import React from "react";
import styled from "styled-components";

import Layout from "../components/Layout/index";
import SEO from "../components/Seo";

const TermsWrapper = styled.section`
  background-color: ${(props) => props.theme.colors.mainBG};

  .list {
    margin-left: 40px;
    margin-bottom: 0.8rem;
  }

  ul {
    li {
      list-style: lower-alpha;
      padding-left: 15px;
    }
    .sub-ul {
      margin-left: 80px;
      .sub-list {
        list-style: lower-roman;
        padding-left: 20px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    ul {
      .list {
        margin-left: 10px;
      }
      .sub-ul {
        margin-left: 40px;
      }
    }
  }
  @media only screen and (max-width: 425px) {
    ul {
      .sub-ul {
        margin-left: 40px;
      }
    }
  }
  @media only screen and (max-width: 320px) {
    ul {
      .sub-ul {
        margin-left: 30px;
      }
    }
  }
  .last-block {
    margin-bottom: 1.5rem;
  }
`;

const Terms = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <TermsWrapper>
        <div className="inner-wrapper">
          <h1>Terms & Condition</h1>
          <h2>1. About the website</h2>
          <ul>
            <li className="list">Welcome to the madebyHaT (the ‘Website’).</li>
            <li className="list">
              The Website operates under ABN 64 203 813 365. Access to and use of the Website, or any of its associated
              Services, is provided by madebyHaT. Please read these terms and conditions (the 'Terms') carefully. By
              using, browsing and/or reading the Website, this signifies that you have read, understood and agree to be
              bound by the Terms. If you do not agree with the Terms, you must cease usage of the Website, or any of the
              Services, immediately.
            </li>
            <li className="list last-block">
              madebyHaT reserves the right to review and change any of the Terms by updating this page at its sole
              discretion. When madebyHaT updates the Terms, it will use reasonable endeavours to provide you with notice
              of updates to the Terms. Any changes to the Terms take immediate effect from the date of their
              publication. Before you continue, we recommend you keep a copy of the Terms for your records.
            </li>
          </ul>

          <h2>2. Acceptance of the terms</h2>
          <ul>
            <li className="list last-block">
              You accept the Terms by remaining on the Website. You may also accept the Terms by clicking to accept or
              agree to the Terms where this option is made available to you by madebyHaT in the user interface.
            </li>
          </ul>

          <h2>3. Copyright and intellectual property</h2>
          <ul>
            <li className="list">
              The Website, the Services and all of the related products of madebyHaT are subject to copyright. The
              material on the Website is protected by copyright under the laws of Australia and through international
              treaties. Unless otherwise indicated, all rights (including copyright) in the Services and compilation of
              the Website (including but not limited to text, graphics, logos, button icons, video images, audio clips,
              Website, code, scripts, design elements and interactive features) or the Services are owned or controlled
              for these purposes and are reserved by madebyHaT or its contributors.
            </li>
            <li className="list">
              All trademarks, service marks and trade names are owned, registered and/or licensed by madebyHaT.
            </li>
            <li className="list">
              retains all rights, title and interest in and to the Website and all related Services. Nothing you do on
              or in relation to the Website will transfer any:
            </li>
            <ul className="sub-ul">
              <li className="sub-list">
                business name, trading name, domain name, trademark, industrial design, patent, registered design or
                copyright, or
              </li>
              <li className="sub-list">
                a right to use or exploit a business name, trading name, domain name, trademark or industrial design, or
              </li>
              <li className="sub-list last-block">
                a thing, system or process that is the subject of a patent, registered design or copyright (or an
                adaptation or modification of such a thing, system or process), to you.
              </li>
            </ul>
          </ul>

          <h2>4. Privacy</h2>
          <ul>
            <li className="list last-block">
              madebyHaT takes your privacy seriously and any information provided through your use of the Website and/or
              Services are subject to madebyHaT’s Privacy Policy, which is available on the Website.
            </li>
          </ul>

          <h2>5. General disclaimer</h2>
          <ul>
            <li className="list">
              Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions implied
              or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may
              not be limited or excluded.
            </li>
            <li className="list">Subject to this clause, and to the extent permitted by law:</li>
            <ul className="sub-ul">
              <li className="sub-list">
                all terms, guarantees, warranties, representations or conditions which are not expressly stated in the
                Terms are excluded; and
              </li>
              <li className="sub-list">
                madebyHaT will not be liable for any special, indirect or consequential loss or damage (unless such loss
                or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer
                Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with
                the Services or these Terms (including as a result of not being able to use the Services or the late
                supply of the Services), whether at common law, under contract, tort (including negligence), in equity,
                pursuant to statute or otherwise.
              </li>
              <li className="sub-list">
                Use of the Website and the Services is at your own risk. Everything on the Website and the Services is
                provided to you "as is" and "as available" without warranty or condition of any kind. None of the
                affiliates, directors, officers, employees, agents, contributors and licensors of madebyHaT makes any
                express or implied representation or warranty about the Services or any products or Services (including
                the products or services of madebyHaT) referred to on the Website. This includes (but is not restricted
                to) loss or damage you might suffer as a result of any of the following:
              </li>
              <li className="sub-list">
                failure of performance, error, omission, interruption, deletion, defect, failure to correct defects,
                delay in operation or transmission, computer virus or other harmful components, loss of data,
                communication line failure, unlawful third party conduct, or theft, destruction, alteration or
                unauthorised access to records;
              </li>
              <li className="sub-list">
                the accuracy, suitability or currency of any information on the Website, the Services, or any of its
                Services related products (including third party material and advertisements on the Website); (c) costs
                incurred as a result of you using the Website, the Services or any of the products of madebyHaT; and
              </li>
              <li className="sub-list last-block">
                the Services or operation in respect to links which are provided for your convenience.
              </li>
            </ul>
          </ul>

          <h2>6. Limitation of liability</h2>
          <ul>
            <li className="list">
              madebyHaT's total liability arising out of or in connection with the Services or these Terms, however
              arising, including under contract, tort (including negligence), in equity, under statute or otherwise,
              will not exceed the resupply of the Services to you.
            </li>
            <li className="list last-block">
              You expressly understand and agree that madebyHaT, its affiliates, employees, agents, contributors and
              licensors shall not be liable to you for any direct, indirect, incidental, special consequential or
              exemplary damages which may be incurred by you, however caused and under any theory of liability. This
              shall include, but is not limited to, any loss of profit (whether incurred directly or indirectly), any
              loss of goodwill or business reputation and any other intangible loss.
            </li>
          </ul>

          <h2>7. Indemnity</h2>
          <ul>
            <li className="list">
              You agree to indemnify madebyHaT, its affiliates, employees, agents, contributors, third party content
              providers and licensors from and against:
            </li>
            <ul className="sub-ul">
              <li className="sub-list">
                all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees
                on a full indemnity basis) incurred, suffered or arising out of or in connection with Your Content;
              </li>
              <li className="sub-list">
                any direct or indirect consequences of you accessing, using or transacting on the Website or attempt to
                do so; and/or
              </li>
              <li className="sub-list last-block">any breach of the Terms.</li>
            </ul>
          </ul>

          <h2>8. Governing Law</h2>
          <ul>
            <li className="list last-block">
              The Terms are governed by the laws of Western Australia, Australia. Any dispute, controversy, proceeding
              or claim of whatever nature arising out of or in any way relating to the Terms and the rights created
              hereby shall be governed, interpreted and construed by, under and pursuant to the laws of Western
              Australia, Australia, without reference to conflict of law principles, notwithstanding mandatory rules.
              The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of
              the parties hereto and their successors and assigns.
            </li>
          </ul>

          <h2>9. Independent legal advice</h2>
          <ul>
            <li className="list last-block">
              Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties
              having taken the opportunity to obtain independent legal advice and declare the Terms are not against
              public policy on the grounds of inequality or bargaining power or general grounds of restraint of trade.
            </li>
          </ul>

          <h2>10. Severance</h2>
          <ul>
            <li className="list last-block">
              If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that
              part shall be severed and the rest of the Terms shall remain in force.
            </li>
          </ul>
        </div>
      </TermsWrapper>
    </Layout>
  );
};

export default Terms;
